<template>
    <!-- <div class="text-center ma-2"> -->
      <v-snackbar
        v-model="snackbar"
        :class="[`elevation-${elevation}`]"
        :color="color"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        :timeout="timeout"
        dark
      >
      <v-icon
        color="white"
        class="mr-3"
      >
        mdi-bell-plus
      </v-icon>
        <div> {{msg}}</div>
        <v-icon
          size="20"
          @click="snackbar = false"
        >
          mdi-close-circle
        </v-icon>
      </v-snackbar>
    <!-- </div> -->
</template>

<script>
export default {
  data () {
    return {
      snackbar: true,
    }
  },
  // mounted(){
  //   this.$nextTick(()=>{
  //     // this.init();
  //   })
  // },
  // methods: {
  //   init(){
  //     setTimeout(() => {
  //       this.snackbar = false
  //     }, 5000);
  //   }
  // },
  props: {
    msg: {
      type: String,
      default: '-'
    },
    elevation: {
      type: [Number, String],
      default: 6
    },
    color: {
      type: String,
      default: 'indigo',
    },
    timeout:{
      type: Number,
      default: 5000
    },
    bottom: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: true
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style>
.v-alert--notification {
  border-radius: 4px !important;
  border-top: none !important;
}
.v-application .elevation-6 {
    -webkit-box-shadow:none !important; 
    box-shadow: none!important;
}
</style>
