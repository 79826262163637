<template>
<v-app>
      <core-filter/>
      <core-toolbar style="width:fixed;"/>
      <core-drawer/>
      <v-main style="background: #eee;padding-top: 64px;width: fixed;">
        <div id="core-view">
          <v-fade-transition  mode="out-in">
              <router-view />
          </v-fade-transition>
        </div>
      <core-footer/>
      </v-main>
</v-app>
</template>

<script>
export default {
}
</script>

<style>
#core-view {
  padding-bottom: 200px;
}
</style>
