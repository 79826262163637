export const Zen = {
  userManage: {
    search: 'Please input keyword ...',
    editBtn: 'Edit',
    deleteBtn: 'Delete',
    searchBtn: 'Search',
    close: 'Close',
    confirm: 'Confirm',
    save: 'Save',
    update: 'Update',
    delTooltip: 'Confirm delete data?',
    warning: 'warning',
    success: 'success',
    bloc: {
      title: 'Bloc Manage',
      insertBtn: 'Add Bloc',
      editBtn: 'Edit Bloc',
      table: {
        id: 'ID',
        name: 'name',
        contact: 'contact',
        domain: 'domain',
        action: 'action'
      },
      rules: {
        name: 'data is null',
        contact: 'data is null',
        domain: 'data is null',
      }
    },
    site: {
      title: 'Site Manage',
      insertBtn: 'Add Site',
      editBtn: 'Edit Site',
      refurbishBtn: 'Refurbish key',
      refurbishTooltip: 'Confirm refurbish site key?',
      updateRadacctStatusTooltip: 'Confirm open or stop radacct?',
      downloadBtn:'Download Report',
      table: {
        id: 'ID',
        name: 'name',
        contact: 'contact',
        groupName: 'bloc',
        areaCode: 'code',
        areaName: 'area',
        provinceName: 'province',
        cityName: 'city',
        key: 'key',
        remark:'remark',
        radaccePrice: 'Radacce Price',
        action: 'action',
        status: 'radacct status',
        attachment:'Acceptance report',
        hasattachment:'Acceptance report has been uploaded,click to change it',
        openRadacct:'Open radacct',
        pauseRadacct:'Pause radacct'
      },
      rules: {
        name: 'data is null',
        contact: 'data is null',
        areaCode: 'data is null',
        groupID: 'data is null',
      }
    },
    user: {
      title: 'User Manage',
      insertBtn: 'Add User',
      editBtn: 'Edit User',
      statusBtn: 'Change Status',
      allowList: '可信任网段',
      vlanExplain: '多个vlan用换行符分割',
      table: {
        id: 'ID',
        username: 'username',
        password: 'password',
        passwordDefault: 'password(this can null)',
        name: 'name',
        role: 'role',
        contact: 'contact',
        type: {
          text: 'user type',
          admin: 'Admin User',
          group: 'Group User',
          shop: 'Shop User',
        },
        roleName: 'role',
        groupName: 'group',
        shopName: 'site',
        provinceName: 'province',
        cityName: 'city',
        areaName: 'area',
        status: {
          text: 'status',
          on: 'On',
          off: 'Off'
        },
        action: 'action'
      },
      rules: {
        username: 'data is null',
        password: 'data is null',
        name: 'data is null',
        contact: 'data is null',
        type: 'data is null',
        group: 'data is null',
        shop: 'data is null',
        vlan: '数据不能为空'
      }
    },
    role: {
      title: 'Role Manage',
      configBtn: 'config Permission',
      insertBtn: 'Add Role',
      editBtn: 'Edit Role',
      table: {
        id: 'ID',
        name: 'name',
        groupName: 'bloc',
        action: 'action'
      },
      rules: {
        name: 'data is null',
        groupID: 'data is null',
      }
    }
  },
  systemManage: {
    editBtn: 'Edit',
    deleteBtn: 'Delete',
    searchBtn: 'Search',
    close: 'Close',
    confirm: 'Confirm',
    save: 'Save',
    update: 'Update',
    delTooltip: 'Confirm delete data?',
    syslog: {
      title: {
        user: 'User Action Log',
        aaa: 'AAA Log',
        device: 'Device Log',
        netsafe: 'Net Safe Log',
        sms: 'Sms Log'
      },
      table: {
        id: 'ID',
        type: {
          text: 'log level'
        },
        content: 'log content',
        createdAt: 'create time'
      }
    },
    place: {
      insertBtn: {
        province: 'Add Province',
        city: 'Add City',
        area: 'Add Area'
      },
      editBtn: {
        province: 'Edit Province',
        city: 'Edit City',
        area: 'Edit Area'
      },
      title: 'Place Manage',
      table: {
        provinceName: 'province',
        cityName: 'city',
        code: 'code',
        name: 'name',
        action: 'action'
      },
      rules: {
        code: 'data is null',
        name: 'data is null',
      }
    },
    permission: {
      title: 'Permission Manage',
      insertBtn: 'Add Permission',
      editBtn: 'Edit Permission',
      table: {
        id: 'ID',
        name: 'name',
        router: 'router',
        action: 'action'
      },
      rules: {
        name: 'data is null',
        router: 'data is null'
      }
    }
  },
  portalManage: {
    editBtn: 'Edit',
    designBtn: '设计页面',
    deleteBtn: 'Delete',
    searchBtn: 'Search',
    copyBtn: 'Copy',
    close: 'Close',
    confirm: 'Confirm',
    save: 'Save',
    update: 'Update',
    delTooltip: 'Confirm delete data?',
    demo: {
      insertBtn: 'Add Demo',
      editBtn: 'Edit Demo',
      selectBtn: 'Select Demo',
      htmlBtn: 'Edit HTMl',
      title: {
        system: 'System Demo',
        user: 'User Demo'
      },
      table: {
        name: 'name',
      },
      rules: {
        name: 'data is null'
      }
    },
    ac: {
      insertBtn: 'Add Ac',
      editBtn: 'Edit Ac',
      apmacBtn: 'Set APMAC',
      webhookBtn: 'Set WebHook',
      title: 'Ac Manage',
      table: {
        groupName: 'bloc',
        shopName: 'site',
        provinceName: 'province',
        cityName: 'city',
        areaName: 'area',
        id: 'ID',
        name: 'name',
        identCode: 'ident code',
        scheduler: 'scheduler',
        smsNet: 'sms',
        smsNetInter: 'other sms',
        userAc: 'user',
        macAc: 'mac',
        phoneAllowList: '手机号白名单库',
        portalAddress: 'portalAddress',
        portalKey: 'portalKey',
        apMac: 'APMAC',
        webHook: 'WebHook',
        action: 'action',
        nasid: 'NASID',
        nasip: 'NASIP',
        psk: 'PSK',
        aaaClient:'aaa client',
      },
      rules: {
        name: 'data is null',
        identCode: 'data is null',
        scheduler: 'data is null',
        smsNet: 'data is null',
        userAc: 'data is null',
        macAc: 'data is null',
        groupName: 'data is null',
        shopName: 'data is null',
        areaName: 'data is null',
      }
    },
    scheduler: {
      insertBtn: 'Add Scheduler',
      editBtn: 'Edit Scheduler',
      rulesBtn: 'Edit Scheduler rules',
      title: 'Scheduler',
      table: {
        id: 'ID',
        name: 'name',
        groupName: '集团',
        shopName: '场所',
        demo: 'demo hTML',
        action: 'action',
        rule: 'Rules',
        time: 'Time',
        ua: 'Deivce',
        mac: 'MAC'
      },
      rules: {
        name: 'data is null',
        demo: 'data is null',
      },
      tip: {
        nonePage: '暂无可选择的portal模板，请先添加用户模板'
      }
    }
  }
}