import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import {createHttpInstance} from './utils/http'

import Vuetify from 'vuetify'
import vuetify from '@/plugins/vuetify'
import dialog from './components/material/DialogIndex'

import { Notification,Upload} from 'element-ui';
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';

import 'echarts/lib/chart/line'
import echarts from 'echarts' 

import dataV from '@jiaminghi/data-view'
import moment from "moment";


Vue.use(dataV)
Vue.use(Upload)
Vue.use(ElementUI)
Vue.use(dialog)
Vue.use(Vuetify)


// Components
import './components'

// Plugins
import './plugins'

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts


Vue.prototype.$http = createHttpInstance()
Vue.prototype.$http.setDefaultErrorHandler((error) => {
    if(error.errmsg)
    {
		Notification({
			type: 'error',
			message: error.errmsg
		});
    }   
    if(error.url)
    {   
        if((typeof error.url) == "string")
        {   
            location.href = error.url
        }   
        else
        {   
            router.push(error.url)
        }   
    }   
})

router.beforeEach(function(to, from, next) {
  if (to.meta.requiresAuth) {
      if (sessionStorage.getItem("authUser")) {
          next(); 
      } else {
          next('/login')
      }
  } else {
      //不需要登录
      next(); 
  }
});


new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
