import dialog from './Dialog.vue'
import Vue from 'vue'

let dialogConstructor = Vue.extend(dialog)
let instance
let seed = 1
let index = 2000
const install = () => {
    Object.defineProperty(Vue.prototype, '$dialog', {
      get () {
        let id = 'dia_' + seed++
        const dialogMsg = options => {
          instance = new dialogConstructor({
            propsData: options
          })
          index++
          instance.id = id
          instance.vm = instance.$mount()
  //      
  //      if(seed>2){
  //      		if(document.getElementById('message_'+ (seed-2)) !=null){
  //      			instance.vm.$el.style.marginTop = (index-2000-seed+2)*80+'px';
  //      		}
  //      }
          document.getElementById('app').appendChild(instance.vm.$el)
          instance.vm.$el.style.zIndex = index;
          instance.vm.$el.id=id;
          
  //      if(document.querySelector('#message_'+seed-1)){
  //      	
  //      }
  //      instance.vm.$el.;
          return instance.vm
        }
        return dialogMsg
      }
    })
}
  

export default install