import { set, toggle,setData } from '@/utils/vuex'

export default {
  setDrawer: set('drawer'),
  setImage: set('image'),
  setColor: set('color'),
  toggleDrawer: toggle('drawer'),
  roleList:(state,roleList) => {
      state.rolelists = roleList.data;
  }
}
