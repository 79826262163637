<template>
    <div id="v-table">
        <v-data-table 
            :footer-props="footer_props"
            :headers="columns" 
            :items="data"
            :loading="loading"
            class="elevation-1"
            :server-items-length="total"
            :options.sync="options"
        >
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>
                        {{ title }}
                    </v-toolbar-title>
                    <v-divider 
                        class="mx-4" 
                        inset 
                        vertical
                    ></v-divider>
                    <slot name="title"></slot>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <slot name="body"></slot>
            </template>
            <template v-slot:[`item.action`]="{ item }">
                <slot name='action' :value='{ item }'></slot>
            </template>
            <template slot="footer.page-text" slot-scope="page_text">
                {{page_text.pageStart}} - {{page_text.pageStop}} 共 {{page_text.itemsLength}}
                <v-btn class="table-btn-restore" tile depressed text @click="getDataFromApi">
                    <v-icon left color="rgba(0, 0, 0, 0.54)">mdi-restore</v-icon>
                    刷新
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>

export default {

    data () {
        return {
            footer_props:{
                showFirstLastPage: true,
                firstIcon: 'mdi-chevron-double-left',
                lastIcon: 'mdi-chevron-double-right',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right'
            },
            options: {
            },
            search: '',
            total: 0,
            data: [],
            pageSize: 10,
            currentPage: 1,
            loading: false
        }
    },

    props: {
        methods: String,
        url: String,
        filter: Object,
        title: String,
        columns: Array,
        searchValue: Number,
        //currentPage: Number,
    },
    watch: {
        filter:{
            handler(){
                if(this.searchValue == 1){
                    this.getDataFromApi()
                }
            }
        },
        currentPage:{
            handler(){
                this.options.page = this.currentPage
            }
        },
        options: {
            handler (newValue,oldValue) {
                if(JSON.stringify(this.filter) != "{}"){
                    if (oldValue.page !== undefined && newValue !== oldValue) {
                        this.pageSize = newValue.itemsPerPage
                        this.currentPage = newValue.page
                    }
                    this.getDataFromApi()
                }
            },
            deep: true,
        },
    },
    methods: {
        getDataFromApi() {
            let url = this.url
            let filter = this.filter
            this.postData = {
                filter,
                pageSize: this.pageSize,
                currentPage: this.currentPage,
            }
            this.loading = true
            this.$http.post(url,this.postData).then(resp => {
                if(!resp) return
                this.total = parseInt(resp.data.total);
                if (resp.data.list == null){
                  this.data = []
                  return
                }
                this.data = resp.data.list
            }).finally(resp => {
                this.$emit('searchValue')
                this.loading = false
            })
        },

        searchTable(){
            this.currentPage = 1
            this.getDataFromApi()
        },

    },

    
}
</script>
<style language="scss">
.table-btn-restore{
    font-weight: 300;
}
#v-table .span {
    padding-right: 6px;
}
 
#v-table .span i {
    color: #1976D2!important;
}   
 
#v-table table thead tr th {
    color: #333;
    font-size: 17px;
    font-weight: 300;
}
 
#v-table .v-card--material__header {
    width: 175px;
}
</style>