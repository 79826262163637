<template>
  <v-app>
      <div id="app" style="height:100%;">
          <router-view/>
      </div>
  </v-app>
</template>
<script>
export default {
  name:'App',
}
</script>
<style lang="scss">
@import '@/styles/index.scss';

/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
</style>
