/*
|--------------------------------------------------------------------------
| Tips
|--------------------------------------------------------------------------
| API URL
*/

const Api =  {
  rbac: {
    groupAddUrl: '/admin/user/group/add',
    groupListUrl: '/admin/user/group/list',
    groupCommandListUrl: '/admin/gateway/device/groupcommandlist',
    groupCommandAddUrl:'/admin/gateway/device/groupcommandadd',
    groupDetailUrl: '/user/group/getdetail',
    groupEditUrl: '/admin/user/group/edit',
    groupDeleteUrl: '/admin/user/group/delete',
    shopAddUrl: '/admin/user/shop/add',
    shopListUrl: '/admin/user/shop/list',
    shopDetailUrl: '/user/shop/getdetail',
    shopEditUrl: '/admin/user/shop/edit',
    shopDeleteUrl: '/admin/user/shop/delete',
    shopResetKeyUrl: '/admin/user/shop/resetKey',
    setRadacctStatusUrl: '/admin/user/shop/setRadacctStatus',
    downloadReportUrl:'/admin/user/shop/download',

    accountAddUrl: '/admin/user/account/add',
    accountListUrl: '/admin/user/account/list',
    accountDetailUrl: '/user/account/getdetail',
    accountEditUrl: '/admin/user/account/edit',
    accountDeleteUrl: '/admin/user/account/delete',
    accountSetStatusUrl: '/admin/user/account/setstatus',
    roleAddUrl: '/admin/user/role/add',
    roleListUrl: '/admin/user/role/list',
    roleDetailUrl: '/admin/user/role/getdetail',
    roleEditUrl: '/admin/user/role/edit',
    roleDeleteUrl: '/admin/user/role/delete',
    accountEditVlan: '/admin/user/account/editVlan'
  },
  portal: {
    acListUrl: '/admin/portal/authzone/list',
    zonenameListUrl: '/admin/portal/authzone/zonenameList',
    acDetailUrl: '/admin/portal/authzone/getdetail',
    acAddUrl: '/admin/portal/authzone/add',
    acDeleteUrl: '/admin/portal/authzone/delete',
    acEditUrl: '/admin/portal/authzone/edit',
    acSetApmacsUrl: '/admin/portal/authzone/setapmacs',
    acSetWebhookUrl: '/admin/portal/authzone/setwebhook',
    acSetDefaultUrl: '/admin/portal/authzone/setdefault',
    acSetRulesUrl: '/admin/portal/authzone/setrules',
    scheduleListUrl: '/admin/portal/schedule/list',
    scheduleDetailUrl: '/admin/portal/schedule/getdetail',
    scheduleAddUrl: '/admin/portal/schedule/add',
    scheduleDeleteUrl: '/admin/portal/schedule/delete',
    scheduleEditUrl: '/admin/portal/schedule/edit',
    scheduleSetRulesUrl: '/admin/portal/schedule/setrules',
    udemoListUrl: '/admin/portal/page/list',
    udemoDetailUrl: '/admin/portal/page/getdetail',
    udemoAddUrl: '/admin/portal/page/add',
    udemoCopyAddUrl: '/admin/portal/page/copy',
    udemoDeleteUrl: '/admin/portal/page/delete',
    udemoEditUrl: '/admin/portal/page/edit',
    udemoEditContentUrl: '/admin/portal/page/editcontent',
    sdemoListUrl: '/admin/portal/tpl/list',
    sdemoDetailUrl: '/portal/tpl/getdetail',
    sdemoAddUrl: '/admin/portal/tpl/add',
    sdemoDeleteUrl: '/admin/portal/tpl/delete',
    sdemoEditUrl: '/admin/portal/tpl/edit',
    sdemoEditContentUrl: '/portal/tpl/editcontent',
    demoUploadContentUrl: '/portal/editor/upload',
    radcheckListUrl: '/radius/radcheck/listset',
    gatewayListUrl: '/sms/gateway/list',
    getApgroups: '/admin/portal/authzone/getApgroups',
    setApgroups: '/admin/portal/authzone/setApGroup',
    getPortalTypes: '/admin/portal/type/list',
    addPortalType: '/admin/portal/type/add',
    delPortalType: '/admin/portal/type/delete'
  },
  system: {
    permissionListUrl: '/admin/privilege/list',
    permissionDetailUrl: '/admin/privilege/getdetail',
    permissionAddUrl: '/admin/privilege/add',
    permissionDeleteUrl: '/admin/privilege/delete',
    permissionEditUrl: '/admin/privilege/edit',
    placeListUrl: '/admin/location/list',
    placeDetailUrl: '/admin/location/getdetail',
    placeAddUrl: '/admin/location/add',
    placeDeleteUrl: '/admin/location/delete',
    placeEditUrl: '/admin/location/edit',
    LogListUrl: '/admin/system/log/action',
    getLcrFileUrl:'/admin/system/license/getLcrFile',
    uploadLcsFileUrl:'/admin/system/license/postLcsFile',
    getLcsStatusUrl:'/admin/system/license/getLcsStatus',
    cancelLcsUrl:'/admin/system/license/cancelLcs'   
}, 
  gateway:{
    deviceTobindlist:'/gateway/device/tobindlist',
    deviceBind:'/gateway/device/bind',
    deviceList:'/admin/gateway/device/list',
    deviceGetDetail:'/admin/gateway/device/getdetail',
    deviceEdit:'/admin/gateway/device/edit',
    deviceAdd:'/admin/gateway/device/add',
    deviceDelete:'/admin/gateway/device/delete',
    deviceToaudit:'/admin/gateway/device/toaudit',
    showlog:'/admin/gateway/device/showlog',
    downlog:'/admin/gateway/device/downlog',
    netlog:'/admin/gateway/device/netlog',
    commandList:'/admin/gateway/command/list',
    commandGetdetail:'/admin/gateway/command/getdetail',
    commandExcute:'/admin/gateway/command/excute',
    emailList: '/admin/gateway/email/list',
    emailAdd: '/admin/gateway/email/add',
    emailDelete: '/admin/gateway/email/delete',
    emailEdit: '/admin/gateway/email/edit',
    eamilTest:'/admin/gateway/email/test',
    alertList: '/admin/gateway/alert/list',
    alertAdd: '/admin/gateway/alert/add',
    alertEdit: '/admin/gateway/alert/edit',
    alertDelete: '/admin/gateway/alert/delete',
    alertBindList:'/admin/gateway/alert/bindList',
    alertBindAdd:'/admin/gateway/alert/bindAdd',
    alertBindDelete:'/admin/gateway/alert/bindDelete',
    alertRecordList:'/admin/gateway/alertrecord/list',
    alertRcordExport:'/admin/gateway/alertrecord/export',
    mwanlog:'/admin/gateway/device/mwanlog',
    ordermwannetlist:'/admin/gateway/device/mwan/ordermwannetlist',
    mwannetlist:'/admin/gateway/device/mwan/mwannetlist',
  },
  device:{
    apGroupList: '/admin/device/apgroup/list',
    addApGroup: '/admin/device/apgroup/add',
    editApGroup: '/admin/device/apgroup/edit',
    delApGroup: '/admin/device/apgroup/delete',
    apList: '/admin/device/ap/list',
    addAp: '/admin/device/ap/add',
    editAp: '/admin/device/ap/edit',
    delAp: '/admin/device/ap/delete',
    getApimportTemplateUrl: '/admin/device/ap/importTemplate',
    importAp: '/admin/device/ap/import'
  },
  editconfig:{
    loginEditconfig:'/config/loginconfig',
    getconfigInfo:'/config/getconfiginfo',
    saveconfigInfo:'/config/saveconfiginfo',
    setInitDB:'/config/setinitdb',
    statusInitDB:'/config/statusinitdb',
    getsystemInfo:'/config/getdefualt',
    changesystem:'/config/changesystemtype',
  },
  auth:{
    loginUrl: '/admin/login',
    menuitemUrl: '/admin/menuitem',
    logoutUrl: '/admin/logout',
    modifypwd: '/admin/modifypwd'
  },
  sms:{
    sdkList:'/admin/sms/sdk/list',
    sdkDelete:'/admin/sms/sdk/delete',
    sdkListScan:'/admin/sms/sdk/listscan',
    gatewayList: '/admin/sms/gateway/list',
    gatewayAdd: '/admin/sms/gateway/add',
    gatewayEdit: '/admin/sms/gateway/edit',
    gatewayDel: '/admin/sms/gateway/delete',
    recordList: '/admin/sms/record/list',
  },
  data:{
    userflowList:'/analysis/userflow/list',
    userflowDetail: '/analysis/userflow/show/id/',
    appList: '/analysis/applicationflow/list',
    deviceList: '/gateway/device/list',
    authList: '/admin/analysis/authdetail/list',
    authtypeList: '/admin/analysis/authdetail/authtypeList',
    radAuthDetailList: '/admin/analysis/radauthdetail/list',
    offlineDevice: '/analysis/authdetail/offlineDevice',
    bindwidthup: '/analysis/bindwidth/up',
    bindwidthdown: '/analysis/bindwidth/down',
    bindwidthMonth: '/analysis/bindwidth/month',
    bindwidthDay: '/analysis/bindwidth/day',
    bindwidthHour: '/analysis/bindwidth/hour',
    screenProvince:'/Analysis/Screen/province',
    applicationflowTop:'/Analysis/Applicationflow/top',
    visitorList:'/analysis/authdetail/visitorList',
    exportAuthdetail: '/admin/analysis/authdetail/export',
  },
  dashboard:{
    index: '/admin/analysis/index',
    upflow: '/analysis/upflow',
    downflow: '/analysis/downflow',
    dayauth: '/admin/analysis/dayauth',
    conntrend: '/Analysis/Dashboard/conntrend',
  },
  pick:{
    groupList: '/user/group/list',
    shopList: '/user/shop/list',
    deviceList: '/gateway/device/list',
  },
  aaa: {
    serverList: '/radius/server/list',
    serverAdd: '/radius/server/add',
    serverDelete: '/radius/server/delete',
    serverStatus: '/radius/server/status',
    serverRestart: '/radius/server/restart',
    serverSetport: '/radius/server/setport',
    clientList: '/admin/aaa/client/list',
    clientAdd: '/admin/aaa/client/add',
    clientDelete: '/admin/aaa/client/delete',
    clientEdit: '/admin/aaa/client/edit',
    radcheckListset: '/admin/aaa/set/list',
    radcheckAddset: '/admin/aaa/set/add',
    radcheckDeleteset: '/admin/aaa/set/delete',
    radcheckAddsetattr: '/admin/aaa/set/addAttr',
    radcheckAddsetattrs: '/admin/aaa/set/addAttrs',
    radcheckDelsetattr: '/admin/aaa/set/delAttr',
    radcheckEditsetr: '/admin/aaa/set/edit',
    radcheckListuser: '/admin/aaa/user/list',
    radcheckAdduser: '/admin/aaa/user/add',
    radcheckDeleteuser: '/admin/aaa/user/delete',
    radcheckAdduserattr: '/admin/aaa/user/addAttr',
    radcheckAdduserattrs: '/admin/aaa/user/addAttrs',
    radcheckDeluserattr: '/admin/aaa/user/delAttr',
    radcheckEdituser: '/admin/aaa/user/edit',
    radcheckWechatAdmins: '/admin/aaa/qrauth/adminlist',
    radcheckGetQrcodeUrl: '/admin/aaa/qrauth/getQrcodeUrl',
    radcheckWechatAdminDel: '/admin/aaa/qrauth/wechatAdminDel',
    radcheckcheckWechatAdmin: '/admin/aaa/qrauth/checkWechatAdmin',
    radcheckVisitors: '/radius/radcheck/visitors',
    radcheckAddVisitor: '/radius/radcheck/addVisitor',
    radcheckDelVisitor: '/radius/radcheck/delVisitor',
    radcheckEdituser: '/admin/aaa/user/edit',
    getDownloadTemplateUrl: '/admin/aaa/user/downloadTemplate',
    importTemplate: '/admin/aaa/user/importTemplate',
    radcheckListGroup: '/admin/aaa/class/list',
    radcheckAddGroup: '/admin/aaa/class/add',
    radcheckAddGroupAttrs: '/admin/aaa/class/addAttrs',
    radcheckDelGroupAttr: '/admin/aaa/class/delAttr',
    radcheckEditGroup: '/admin/aaa/class/edit',
    radcheckDelGroup: '/admin/aaa/class/delete',
    radcheckSetUserEnable: '/admin/aaa/user/setuserenable',
    getDownloadVisitorTemplateUrl: '/radius/radcheck/downloadVisitorTemplate',
    importVisitorTemplate: '/radius/radcheck/importVisitorTemplate',
    ldapList:'/admin/aaa/ldap/list',
    ldapEdit:'/admin/aaa/ldap/edit',
    ldapAdd:'/admin/aaa/ldap/add',
    ldapDelete:'/admin/aaa/ldap/delete'
  },
  audit: {
    deviceList: '/audit/device/list',
    deviceDelete: '/audit/device/delete',
    deviceEdit: '/audit/device/edit',
    deviceAdd: '/audit/device/add',
  },
  appfilter: {
    getappfilterlist:'/admin/appfilter/get',
    getappauth:'/admin/appfilter/getappauth',
  },
  clientrecord:{
    listrecord:'/admin/clientrecord/record/list',
    getcofnig:'/admin/clientrecord/config/get',
    setcofnig:'/admin/clientrecord/config/set'
  },
  radacct:{
    statisticsList:'/admin/radacct/statistics/list',
    doStatistics:'/admin/radacct/statistics/doStatistics',
    export:'/admin/radacct/statistics/export',
    // radacctRuleList: '/admin/radacct/rules/list',
    // radacctRuleAdd:'/admin/radacct/rules/add',
    // radacctRuleEdit:'/admin/radacct/rules/edit',
    // radacctRuleDelete:'/admin/radacct/rules/delete', 
    // setRules:'/admin/radacct/rules/setrules', 
  },
  toplist:{
    apptop:'/admin/analysis/top/topapp',
    topclient:'/admin/analysis/top/topclient',
  }
}

export default Api
