<template>
	<div >
		<v-menu
		:close-on-content-click="false"
		bottom
		left
		min-width="300"
		max-width="300"
		nudge-left="12"
		offset-x
		origin="right top"
		transition="scale-transition"
		>
			<template v-slot:activator="{ on }">
				<v-btn
				class="elevation-0 setting-btn-custom"
				color="grey"
				fixed
				fab
				style="width:42px;height:40px;z-index:9;top: 18%;border-radius: 0.4em 0 0 0.4em;background-color: rgba(0, 0, 0, 0.25) !important;"
				top
				v-on="on"
				>
					<v-icon style="font-size: 1.8em;color:#fff">mdi-settings</v-icon>
				</v-btn>
			</template>
			<v-card>
				<v-container grid-list-xl >
					<v-layout wrap style="margin:0;">
					<v-flex xs12>
						<div class="text-xs-center text-uppercase sidebar-filter" style="text-align:center">{{$vuetify.lang.t('$vuetify.filter.setting')}}</div>

						<v-layout justify-center>
						<v-avatar
							v-for="c in colors"
							:key="c"
							:class="[c === color ? 'color-active color-' + c: 'color-'+c]"
							class="avatar-hover"
							size="23"
							@click="setColor(c);"
						/>
						</v-layout>
						<v-divider class="mt-3"/>
					</v-flex>
					<v-flex
						xs12
					>
						<div class="text-xs-center text-uppercase sidebar-filter" style="text-align:center">{{$vuetify.lang.t('$vuetify.filter.image')}}</div>
					</v-flex>
					<v-flex
						v-for="img in images"
						:key="img"
						xs3
					>
						<v-img
						:class="[image === img ? 'image-active' : '']"
						class="img-hover"
						:src="img"
						height="120"
						@click.native="setImage(img)"
						/>
					</v-flex>
					<v-flex xs12>
						<v-btn
						target="_blank"
						class="white--text"
						color="success"
						block
						>
						<span style="color:#fff">{{$vuetify.lang.t('$vuetify.filter.doc')}}</span>
						</v-btn>
					</v-flex>
					<v-flex xs12>
						<div class="text-center body-2 text-uppercase">
							<div class=" sidebar-filter">
								{{$vuetify.lang.t('$vuetify.filter.thank')}}
							</div>
						</div>
					</v-flex>
					<v-flex xs12>
						<div class="text-center body-2 text-uppercase">
						<el-radio-group v-model="language" size="small" @change="changeLang">
							<el-radio-button label="zh" >中文</el-radio-button>
							<el-radio-button label="en">English</el-radio-button>
						</el-radio-group>
						</div>
					</v-flex>
					</v-layout>
				</v-container>
			</v-card>
		</v-menu>
	</div>
</template>

<script>
// Utilities
import {
  mapMutations,
  mapState
} from 'vuex'

export default {
  data: () => ({
    colors: [
	  'primary',
	  'secondary',
	  'accent',
	  'error',
      'success',
	  'warning',
    ],
    images: [
		'/static/img/backgroud/sidebar-default.jpg',
		'/static/img/backgroud/sidebar-2.32103624.jpg',
		'/static/img/backgroud/sidebar-3.3a54f533.jpg',
		'/static/img/backgroud/sidebar-4.3b7e38ed.jpg'
    ],
	language:localStorage.getItem('language')=='en'?'en':'zh'
  }),

  computed: {
    ...mapState('app', ['image', 'color']),
    color () {
      this.$store.state.app.color
      this.$store.state.app.image
    }
  },

  methods: {
    ...mapMutations('app', ['setImage']),
    setColor (color) {
    	this.$store.state.app.color = color
    },
	changeLang(val){
		console.log(val);
		localStorage.setItem("language",val)
		location.reload()
	}
  }
}
</script>

<style lang="scss">
  .v-avatar,
  .v-responsive {
	  
    cursor: pointer;
  }
  .setting-btn-custom {
	  height:48px;z-index:999;top: 96px;opacity:0.8;font-size: 1.8rem;border-radius: 0.3em 0 0 0.3em;
  }

  .img-hover:hover {
    border-color: #0bf;
  }
  .avatar-hover:hover {
	  border-color: #0bf;
  }
  .color-active {
	   border-color: #0bf!important;
  }

</style>
