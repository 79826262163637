
/*
|--------------------------------------------------------------------------
| System config
|--------------------------------------------------------------------------
| system version 
| system passportClient
| system baseUrl
| system encryptKey
| system autoRefreshTime
|
| the client use password mode to auth. So the password_client column should be true (1).
*/

const systemConfig = {
    'baseUrl': 'http://127.0.0.1:9501/',
    'testUrl':'',
    'version': '1.0.0',
    'encryptKey': 'justGuess',
    'autoRefreshMinTime': '20000',
    'autoRefreshMidTime': '15000',
    'autoRefreshMaxTime': '10000',
    'aesEncryptKey': '',
    'aesIv': '',
    'aesKey': '',
    'hand_key': '',
    'language':'zh',
    'clientId': '1',
    'clientSecret': 'P2h0e1i8c0l1o0u1d888aaaqsBVXTbV2wsBEm5cq',
}

export default systemConfig
