<template>
	<div>
		<v-toolbar id="core-toolbar" flat :prominent="false" style="background: #eee;position: fixed;
    z-index: 2;width: 100%;margin:fixed">
			<div class="v-toolbar-title" style="margin-top: 0px; padding-right: 0px;  transform: translateY(0px); background: rgb(238, 238, 238);">
				<v-toolbar-title class="tertiary--text font-weight-light" style="margin-left:260px;">
					<v-btn v-if="responsive" class="default v-btn--simple" dark icon @click.stop="onClickBtn">
						<v-icon>mdi-view-list</v-icon>
					</v-btn>
					{{ title }}
				</v-toolbar-title>
			</div>
			<v-spacer />
			<v-toolbar-items>
				<v-flex align-center layout py-2>
					<!-- <v-text-field class="mr-4 purple-input" label="Search..." hide-details color="purple" /> -->
					<router-link v-ripple class="toolbar-items" to="/">
						<v-icon color="tertiary">mdi-view-dashboard</v-icon>
					</router-link>
					<v-menu bottom left content-class="dropdown-menu" offset-y transition="slide-y-transition">
						<router-link v-ripple class="toolbar-items" to="/notifications">
							<v-badge color="error" overlap>
								<template slot="badge">
									{{ notifications.length }}
								</template>
								<v-icon color="tertiary">mdi-bell</v-icon>
							</v-badge>
						</router-link>
						<v-card>
							<v-list dense>
								<v-list-tile v-for="notification in notifications" :key="notification" @click="onClick">
									<v-list-tile-title v-text="notification" />
								</v-list-tile>
							</v-list>
						</v-card>
					</v-menu>
					<v-menu
					:close-on-content-click="false"
					bottom
					left
					nudge-left="12"
					origin="right top"
					style="z-index:10"
					>
						<template v-slot:activator="{ on }">
							<v-icon v-on="on" color="tertiary">mdi-account</v-icon>
						</template>
						<material-card  class="v-card-profile " style="margin-bottom:0">
							<v-avatar slot="offset" class="mx-auto d-block" size="60" style="padding-top:10px">
								<v-img
								:src="src"  
								>
								</v-img>
							</v-avatar>
							<div style="text-align:center">
								{{username}}
							</div>
							<v-card-actions>
								<v-dialog v-model="dialog" persistent max-width="600px">
                                    <template v-slot:activator="{ on }">
										<v-btn text color='black' v-on="on">{{$vuetify.lang.t('$vuetify.common.rePassword')}}</v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title>
                                        <span class="headline">{{$vuetify.lang.t('$vuetify.common.rePassword')}}</span>
                                        </v-card-title>
                                        <v-card-text style="padding:0 100px 20px">
											<v-container>
												<v-form>
												<v-text-field
												v-model="oldPassword"
												:label="$vuetify.lang.t('$vuetify.common.oldPassword')"
												type="password"
												color="indigo"
												prepend-icon="mdi-lock"
												></v-text-field>
												<v-text-field
												v-model="newPassword"
												:label="$vuetify.lang.t('$vuetify.common.newPassword')"
												type="password"
												color="indigo"
												prepend-icon="mdi-lock"
												></v-text-field>
												<v-text-field
												v-model="confirmNewPassword"
												:label="$vuetify.lang.t('$vuetify.common.confirmNewPassword')"
												type="password"
												color="indigo"
												prepend-icon="mdi-lock"
												></v-text-field>
											</v-form>
                                        </v-container>
                                        </v-card-text>
                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="dialog=false;oldPassword=newPassword=confirmNewPassword=''">{{$vuetify.lang.t('$vuetify.common.cancelBtn')}}</v-btn>
                                        <v-btn color="blue darken-1" text v-on:click="confirm">{{$vuetify.lang.t('$vuetify.common.change')}}</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
								
								<v-btn text color='red' v-on:click="logout">{{$vuetify.lang.t('$vuetify.common.logout')}}</v-btn>
							</v-card-actions>
						</material-card>
					</v-menu>
					<!-- user end -->
				</v-flex>
			</v-toolbar-items>
		</v-toolbar>
	</div>
</template>

<script>
	import {
		mapMutations
	} from 'vuex'

	import * as api from './../../api'
	import sha1 from 'js-sha1'
	import md5 from 'js-md5'
	import { Notification  } from 'element-ui';

	export default {
		data: () => ({
			oldPassword: '',
			newPassword: '',
			confirmNewPassword: '',
			dialog: false,

			src: require('../../../public/static/img/logos/user1.jpg'),
			username: '',
			notifications: [
				'Mike, John responded to your email',
				'You have 5 new tasks',
				'You\'re now a friend with Andrew',
				'Another Notification',
				'Another One'
			],
			title: null,
			responsive: false,
			language:localStorage.getItem('language')=='en'?'en':'zh',
		}),

		watch: {
			'$route' (val) {
				var namelist = val.name.split('|')
				if(this.language=="en"){
					this.title = namelist[1]
				}else{
					this.title = namelist[0]
				}
			}
		},

		mounted() {
			var namelist = this.$route.name.split('|')
			if(this.language=="en"){
				this.title = namelist[1]
			}else{
				this.title = namelist[0]
			}
			this.onResponsiveInverted()
			this.username = window.sessionStorage.getItem('name');
			window.addEventListener('resize', this.onResponsiveInverted)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.onResponsiveInverted)
		},

		methods: {
			...mapMutations('app', ['setDrawer', 'toggleDrawer']),
			onClickBtn() {
				this.setDrawer(!this.$store.state.app.drawer)
			},
			logout(){
				this.$http.get(api.default.auth.logoutUrl).then(resp => {
					window.sessionStorage.removeItem('authUser');
					window.sessionStorage.removeItem('type');
					window.sessionStorage.removeItem('contact');
					window.sessionStorage.removeItem('name');
					window.sessionStorage.removeItem('isAuthed');
					this.$router.push('/login');
					Notification({
						type: 'success',
						message: this.$vuetify.lang.t('$vuetify.login.logoutSucccess')
					});
					
				}).finally(resp => {
					
				})
			},
			confirm(){

				const postData = {
					oldpwd: sha1(md5(this.oldPassword)),
					newpwd: sha1(md5(this.newPassword)),
				}
				if(this.oldPassword == '' || this.newPassword == '' || this.confirmNewPassword==''){
					Notification({
						type: 'warning',
						message: this.$vuetify.lang.t('$vuetify.login.confirmNullInput')
					});
				}else if(this.newPassword != this.confirmNewPassword){
					Notification({
						type: 'warning',
						message: this.$vuetify.lang.t('$vuetify.login.confirmNot')
					});
				}else{
					this.$http.post(api.default.auth.modifypwd, postData).then(resp => {
						if(resp.errno == 0){
							this.$router.push('/login');
							Notification({
								type: 'success',
								message: this.$vuetify.lang.t('$vuetify.login.modifypwdSucc')
							});
						}
						
					}).finally(resp => {
                        this.dialog = false
                        this.oldPassword = ''
                        this.newPassword = ''
                        this.confirmNewPassword = ''
					})
				}
					
			},
			onClick() {
				//
			},
			onResponsiveInverted() {
				if(window.innerWidth < 991) {
					this.responsive = true
				} else {
					this.responsive = false
				}
			},
		}
	}
</script>

<style>
	#core-toolbar a {
		text-decoration: none;
	}
</style>