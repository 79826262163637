import axios from 'axios'

const handleError = (instance, error) => {
    if((typeof instance.errorHandler) == "function")
    {
        instance.errorHandler(error)
        return
    }

    if((typeof instance.defaultErrorHandler) == "function")
    {
        instance.defaultErrorHandler(error)
        return
    }
}

export function createHttpInstance() {

    let instance = axios.create()
    instance.defaults.timeout = 30000
    // cookie方式做认证
    instance.defaults.withCredentials = true

    instance.interceptors.response.use(
        function (resp) {
            let data = resp.data
            if(!data || data.errno == undefined)
            {
                let respData = {
                    errno: 2,
                    errmsg: "服务器返回错误,请联系管理员"
                }
                handleError(instance, respData)
                return false
            }
            if(data.errno > 0)
            {
                handleError(instance, data)
                return false
            }
            return data
        },
        function (error) {
            let respData = {
                errno: 1,
                errmsg: error.message
            }
            handleError(instance, respData)
            return false;
        }
    )

    instance.setErrorHandler = (handler) => {
        instance.errorHandler = handler
    }

    instance.setDefaultErrorHandler = (handler) => {
        instance.defaultErrorHandler = handler
    }

    return instance
}
