<template>
	<v-dialog persistent v-model="dialog" :width="widths">
		<v-card>
			<v-card-title class="headline lighten-2" primary-title>
				{{themes}}
			</v-card-title>
			<v-card-text>
				<slot></slot>
			</v-card-text>
			<v-divider></v-divider>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="handleSure('cancel')" >
					{{$vuetify.lang.t('$vuetify.common.cancelBtn')}}
				</v-btn>
				<v-btn :loading="loading" @click="handleSure('sure')" color="primary">
					{{$vuetify.lang.t('$vuetify.common.sureBtn')}}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		data(){
			return {
				themes:this.theme?this.theme:'提示',
				widths:this.width?this.width:500,
			}
		},
		// props: [
		// 	'dialog',
		// 	'theme',
		// 	'width'
		// ],
        props:{
            dialog:{
                type:Boolean,
                default:false
            },
            theme:{
                type:String
            },
            width:{
                type:Number
            },
            loading:{
                type:Boolean,
                default:false
            }
        },
		methods: {
			handleSure(type){
				if(type =='sure'){
					this.$emit('dialogSure',true);
				}else{
					this.$emit('dialogSure',false);
				}
			},
		}
	}
</script>

<style>

</style>
