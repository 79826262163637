<template>
    <v-dialog persistent v-model="show" max-width="500px">
        <v-card>
			<v-card-title>
				<span class="headline">{{ formTitle }}</span>
			</v-card-title>
			<v-card-text>
				<v-container>
					<slot name="content"></slot>
				</v-container>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="closeDialog">取消</v-btn>
				<v-btn color="blue darken-1" text @click="handleSave">确定</v-btn>
			</v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
	export default {
		data(){
			return {
				widths:this.width?this.width:500,
				show:false
			}
		},
		props: {
			width: Number,
			formTitle: String,
			columns: Array
		},
		methods: {
			showDialog(){
				this.show = true
			},
			closeDialog(){
				this.show = false
			},
			handleSave(){
				this.$emit('handleSave')
			}
		}
	}
</script>

<style>

</style>