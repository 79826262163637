export const Zzh = {
  userManage: {
    search: '请输入关键字搜索...',
    editBtn: '编辑',
    deleteBtn: '删除',
    searchBtn: '查询',
    close: '取消',
    confirm: '确认',
    save: '保存',
    update: '更新',
    delTooltip: '确认删除数据？',
    warning: '请求失败',
    success: '操作成功',
    bloc: {
      title: '集团管理',
      insertBtn: '添加集团',
      editBtn: '编辑集团',
      table: {
        id: 'ID',
        name: '名称',
        contact: '联系方式',
        domain: '域名',
        action: '操作'
      },
      rules: {
        name: '数据不能为空',
        contact: '数据不能为空',
        domain: '数据不能为空',
      }
    },
    site: {
      title: '场所管理',
      insertBtn: '添加场所',
      editBtn: '编辑场所',
      refurbishBtn: '刷新密钥',
      refurbishTooltip: '是否刷新场所密钥',
      updateRadacctStatusTooltip: '是否开启或关闭计费',
      downloadBtn:'验收报告',
      table: {
        id: 'ID',
        name: '场所名称',
        contact: '联系方式',
        groupName: '集团',
        areaCode: '地区编号',
        areaName: '地区',
        provinceName: '省份',
        cityName: '城市',
        key: '密钥',
        remark:'备注',
        radaccePrice: '计费金额',
        action: '操作',
        status: '计费状态',
        attachment:'验收报告',
        hasattachment:'验收报告已上传,点击替换',
        openRadacct:'启动计费',
        pauseRadacct:'暂停计费'
      },
      rules: {
        name: '数据不能为空',
        contact: '数据不能为空',
        areaCode: '数据不能为空',
        groupID: '数据不能为空',
      }
    },
    user: {
      title: '用户管理',
      insertBtn: '添加用户',
      editBtn: '编辑用户',
      statusBtn: '更改状态',
      allowList: '可信任网段',
      vlanExplain: '多个vlan用换行符分割',
      table: {
        id: 'ID',
        username: '用户名',
        password: '密码',
        passwordDefault: '密码(若不修改密码此项为空)',
        name: '姓名',
        role: '角色',
        contact: '联系方式',
        type: {
          text: '用户类型',
          admin: '超级管理员',
          group: '集团用户',
          shop: '场所用户',
        },
        roleName: '角色',
        groupName: '集团',
        shopName: '场所',
        provinceName: '省份',
        cityName: '城市',
        areaName: '地区',
        status: {
          text: '状态',
          on: '启用',
          off: '禁用'
        },
        action: '操作'
      },
      rules: {
        username: '数据不能为空',
        password: '数据不能为空',
        name: '数据不能为空',
        contact: '数据不能为空',
        type: '数据不能为空',
        group: '数据不能为空',
        shop: '数据不能为空',
        vlan: '数据不能为空'
      }
    },
    role: {
      title: '角色管理',
      configBtn: '配置权限',
      insertBtn: '添加角色',
      editBtn: '编辑角色',
      table: {
        id: 'ID',
        name: '角色名称',
        groupName: '集团',
        action: '操作'
      },
      rules: {
        name: '数据不能为空',
        groupID: '数据不能为空',
      }
    }
  },
  systemManage: {
    editBtn: '编辑',
    deleteBtn: '删除',
    searchBtn: '查询',
    close: '取消',
    confirm: '确认',
    save: '保存',
    update: '更新',
    delTooltip: '确认删除数据？',
    syslog: {
      title: {
        user: '用户操作日志',
        aaa: 'AAA日志',
        device: '设备日志',
        netsafe: '网安日志',
        sms: '短信日志'
      },
      table: {
        id: 'ID',
        type: {
          text: '日志等级'
        },
        content: '日志内容',
        createdAt: '创建时间'
      }
    },
    place: {
      insertBtn: {
        province: '添加省份',
        city: '添加城市',
        area: '添加地区'
      },
      editBtn: {
        province: '编辑省份',
        city: '编辑城市',
        area: '编辑地区'
      },
      title: '地区管理',
      table: {
        provinceName: '省份',
        cityName: '城市',
        code: '编号',
        name: '名称',
        action: '操作'
      },
      rules: {
        code: '数据不能为空',
        name: '数据不能为空',
      }
    },
    permission: {
      title: '权限管理',
      insertBtn: '添加权限',
      editBtn: '编辑权限',
      table: {
        id: 'ID',
        name: '权限名称',
        router: '后端路由',
        action: '操作'
      },
      rules: {
        name: '数据不能为空',
        router: '数据不能为空',
      }
    }
  },
  portalManage: {
    editBtn: '编辑',
    designBtn: '设计页面',
    deleteBtn: '删除',
    searchBtn: '查询',
    copyBtn: '复制',
    close: '取消',
    confirm: '确认',
    save: '保存',
    update: '更新',
    delTooltip: '确认删除数据？',
    demo: {
      insertBtn: '添加模板',
      editBtn: '编辑模板',
      selectBtn: '选择模板',
      htmlBtn: '编辑页面',
      title: {
        system: '系统模板',
        user: '用户模板'
      },
      table: {
        name: '模板名称',
      },
      rules: {
        name: '数据不能为空'
      }
    },
    ac: {
      insertBtn: '添加认证域',
      editBtn: '编辑认证域',
      apmacBtn: '配置APMAC',
      webhookBtn: '配置WebHook',
      title: '认证域',
      table: {
        groupName: '集团',
        shopName: '场所',
        provinceName: '省份',
        cityName: '城市',
        areaName: '地区',
        id: 'ID',
        name: '名称',
        identCode: '标识符',
        scheduler: '模板策略组',
        smsNet: '短信网关',
        smsNetInter: '国际短信网关',
        userAc: 'Portal认证库',
        macAc: 'Radius认证库',
        phoneAllowList: '手机号白名单库',
        portalAddress: 'portal协议认证地址',
        portalKey: 'portal认证密钥',
        apMac: 'APMAC',
        webHook: 'WebHook',
        action: '操作',
        nasid: 'NASID',
        nasip: 'NASIP',
        psk: 'PSK',
        aaaClient:'3A客户端',
      },
      rules: {
        name: '数据不能为空',
        identCode: '数据不能为空',
        scheduler: '数据不能为空',
        smsNet: '数据不能为空',
        userAc: '数据不能为空',
        macAc: '数据不能为空',
        groupName: '数据不能为空',
        shopName: '数据不能为空',
        areaName: '数据不能为空',
      }
    },
    scheduler: {
      insertBtn: '添加模板策略组',
      editBtn: '编辑模板策略组',
      rulesBtn: '编辑模板策略组规则',
      title: '模板策略组',
      table: {
        id: 'ID',
        name: '名称',
        groupName: '集团',
        shopName: '场所',
        demo: '模板页面',
        action: '操作',
        rule: '规则',
        time: '时间范围',
        ua: '设备类型',
        mac: 'MAC'
      },
      rules: {
        name: '数据不能为空',
        demo: '数据不能为空',
      },
      tip: {
        nonePage: '暂无可选择的portal模板，请先添加用户模板'
      }
    },
  },
}