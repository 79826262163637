export const zhLanguage = {
	close: '关闭',
	refresh: '刷新',
	dataIterator: {
		noResultsText: '没有符合条件的结果',
		loadingText: '加载中……',
	},
	dataTable: {
		itemsPerPageText: '每页数目：',
		ariaLabel: {
			sortDescending: '：降序排列。点击以移除排序。',
			sortAscending: '：升序排列。点击以降序排列。',
			sortNone: '：未排序。点击以升序排列。',
		},
		sortBy: '排序方式',
	},
	dataFooter: {
		itemsPerPageText: '每页数目：',
		itemsPerPageAll: '全部',
		nextPage: '下一页',
		prevPage: '上一页',
		firstPage: '首页',
		lastPage: '尾页',
		pageText: '{0}-{1} 共 {2}',
	},
	datePicker: {
		itemsSelected: '已选择 {0}',
	},
	noDataText: '没有数据',
	carousel: {
		prev: '上一张',
		next: '下一张',
		ariaLabel: {
			delimiter: 'Carousel slide {0} of {1}',
		},
	},
	calendar: {
		moreEvents: '还有 {0} 项',
	},
	fileInput: {
		counter: '{0} 个文件',
		counterSize: '{0} 个文件(共 {1})',
	},
	timePicker: {
		am: 'AM',
		pm: 'PM',
	},
	filter: {
		setting: '侧边栏设置',
		image: '背景图',
		doc: '查看文档',
		thank: '感谢使用！'
	},
	common: {
		project: '奕通安全网关集中管理平台',
		page: '页',
		bindBtn: '补全信息',
		editBtn: '编辑',
		deleteBtn: '删除',
		noData: '暂无数据',
		action: '操作',
		sureBtn: '确定',
		returnBtn: '返回',
		cancelBtn: '取消',
		saveBtn: '保存',
		sureLabel: '是',
		cancalLabel: '否',
		tip: '提示',
		loginOut: '退出登录',
		login: '登录',
		register: '注册',
		role: '暂无角色',
		close: '关闭',
		next: '下一步',
		pre: '上一步',
		pass: '跳过',
		pickTimeRange: '选择时间段',
		export: '导出',
		loading: '加载中',
		reload: '重启',
		info: '查看',
		fileInput: '上传',
		search: '搜索',
		look: '查看',
		config: '配置',
		change: '修改',
		rePassword: '修改密码',
		logout: '退出',
		login: '登录',
		confirmNewPassword: '确认新密码',
		newPassword: '新密码',
		oldPassword: '输入密码',
		flowUnit: '流量（TB）',
		people: '单位（人）',
		noresq: '无resp，返回错误，联系管理员',
		errresp: '返回错误',
		flowdw: '',
		flowcnt: '',
		success: '成功',
		errors: '失败',
		edit: '编辑',
		add: '添加',
		confirmdel: '确认删除',
		input: '该字段不能为空',
		del_api_warning: '此操作有可能删除绑定在该sdk下的所有数据！',
		del_gateway_warning: '此操作有可能删除绑定在该网关下的所有数据！',
		formNull: '字段不能为空，请填写正确的格式',
		formatmac: '格式错误(多个ap只支持换行分割)，请输入正确的mac',
		offline: '下线',
		reset: '重置'
	},
	menu: {
		dashboard: '仪表盘',
		search: '全局搜索',
	},
	login: {
		nullResponse: '返回为空',
		nullTip: '用户名或密码不能为空',
		errTip: '用户名或密码错误',
		forget: '忘记密码?',
		input: '请输入用户名和密码',
		person: '个人中心',
		info: '个人信息',
		edit: '编辑信息',
		edit_txt: '完善您的个人资料',
		modifypwdSucc: '修改密码成功',
		logoutSucccess: '登出成功',
		modifypwdErr: '修改密码失败',
		confirmNot: '两次密码输入不一致',
		confirmNullInput: '密码不能为空'
	},
	bindPermission: {
		role: '添加角色',
		permission: '绑定权限',
		account: '添加账号',
		building: '添加建筑',
		set: '配置用户',
	},
	shop: {
		warning: '有而仅能选择一个集团进行绑定',
		table: {
			theme: '门店列表',
			id: 'ID',
			name: '名称',
			province: '省份',
			city: '城市',
			area: '区域',
			address: '地址',
			created_at: '创建时间',
			updated_at: '更新时间',
		},
		update: '更新门店',
		add: '添加门店',
		rule: {
			name: '名称不能为空'
		},
		deleteTip: '是否删除该门店,一旦删除无法恢复!'
	},
	building: {
		deleteTheme: '已删除集团列表',
		table: {
			theme: '集团列表',
			id: 'ID',
			name: '名称',
			desc: '描述',
			created_at: '创建时间',
			updated_at: '更新时间',
		},
		update: '更新集团',
		add: '添加集团',
		rule: {
			name: '名称不能为空'
		},
		deleteTip: "是否确定删除该集团,一旦删除无法恢复!",
		warning: '集团信息不能为空',
	},
	permission: {
		deleteTheme: '已删除权限列表',
		add: '添加权限',
		table: {
		theme: '权限列表',
		id: 'ID',
		name: '路由',
		zh: '名称',
		icon: '图标',
		is_menu: '菜单项',
		created_at: '创建时间',
		updated_at: '更新时间',
		status: '状态',
		height: '权重',
		pid: '从属ID',
		description: '描述',
		},
		rule: {
			name: '路由不能为空',
			zh: '名称不能为空',
			icon: '图标不能为空',
			pid: '从属ID不能为空',
			height: 'height不能为空',
			menu: '菜单项不能为空',
		},
		deleteTip: "是否确定删除该权限!",
		deleteTipForce: '是否确定删除该权限，一旦删除，无法恢复?',
		deleteTipStore: '是否确定还原该权限?',
	},
	user: {
		user: '用户',
		userTip: '完善用户资料',
		userTipAdd: '添加用户资料',
		buttonAdd: '添加用户',
		buttonUpdate: '修改用户',
		buttonUse: '启用',
		theme: '已删除用户列表',
		table: {
			theme: '用户列表',
			name: '用户名称',
			uuid: 'UUID',
			nickname: '昵称',
			password: '密码',
			repassword: '重复密码',
			rememberuser: '记住账号',
			phone: '手机',
			email: '邮件',
			create_at: '创建时间',
			update_at: '更新时间',
			delete_at: '删除时间',
			info: '其他信息',
			tip: '(可修改)',
			tip1: '(必填)'
		},
		rule: {
			name: '类别名称不能空',
			nickname: '昵称不能空',
			password: '密码不能为空',
			phone: '手机不能为空',
			email: '邮件不能为空',
			info: '其他信息不能空',
		},
		tips: '请对该用户进行绑定',
		permissionRole: '所有角色类型',
		permissionBuilding: '所有建筑类型',
		roleTip: '请选择角色进行绑定,不能为空',
		buildingTip: '请选择建筑进行绑定,不能为空',
		warningTip: '角色或者建筑不能为空',
		deleteTip: '是否确定删除该用户',
		deleteTipForce: '是否确定删除该用户，一旦删除，无法恢复?',
		deleteTipStore: '是否确定还原该用户?',
		warning: '用户信息不能为空',
	},
	role: {
		txt: '共',
		userTo: '配置权限点击进入',
		theme: '已删除角色列表',
		permission: '所有权限',
		permission_txt: '请选择的你的权限',
		table: {
			theme: '角色列表',
			name: '角色名称',
			type: '类别',
			description: '描述',
			create_at: '创建时间',
			update_at: '更新时间',
			delete_at: '删除时间',
			action: '操作',
			info: '其他信息',
			tip: '(可修改)',
			tip1: '(必填)'
		},
		rule: {
			type: '类别不能空',
			name: '名称不能空',
			descript: '描述不能空',
			info: '其他信息不能空'
		},
		role: '角色',
		roleTip: '完善角色资料',
		roleTipAdd: '添加角色资料',
		buttonAdd: '添加角色',
		buttonUpdate: '修改角色',
		buttonDelete: '删除角色',
		buttonLock: '设置权限',
		tips: '请选择该角色的权限',
		deleteTip: '是否确定删除该用户',
		deleteTipForce: '是否确定删除该角色，一旦删除，无法恢复?',
		deleteTipStore: '是否确定还原该角色?',
		warning: '角色信息不能为空',
	},
	dashboard: {
		firstTitle1: '在线|总数',
		firstTitle2: '当前在线人数',
		firstTitle3: '累计认证',
		firstTitle4: '累计用户数',
		firstText1: '网关',
		firstText2: '在线人数',
		firstText3: '认证人数',
		firstText4: '总用户数',
		inOutFlowTitle: '上行/下行',
		inOutFlow: '流量',
		connectionTitle: '连接数/TCP/UDP/ICMP',
		connection: '连接数',
		nowTitle: '当前/峰值',
		now: '当前情况',
		ppsTitle: 'PPS/SYN/SYN ACK',
		pps: 'PPS',
		rest: '剩余',
		secondLineTitle: '用户认证趋势',
		secondLineTitle1: '流量分布',
		secondLineTitle2: '连接分布',
		secondLineTitle3: '流量概况',
		fifthLineTitle: '连接趋势',
		new: '新用户',
		old: '老用户',
		all: '总用户',
		thirdLineTitle: '用户模型',
		myChart3_1_title: '应用访问次数占比',
		myChart3_2_title: '用户驻留时间分布',
		myChart3_3_title: '用户驻留时长占比',
		myChart3_4_title: '终端类型占比',
		myChart3_5_title: '终端品牌TOP5',
		thirdLineTitle: '用户模型',
		myChart4_1_title: '流量时间段分布',
		myChart4_2_title: '应用流量时间段分布',
		myChart4_3_title: '认证方式占比',
		myChart4_4_title: '终端平台流量占比',
		myChart4_5_title: '应用使用频率占比TOP5',
		forthLineTitle: '流量模型',
		fifthLineTitle: '上行流量趋势',
		sixthLineTitle: '下行流量趋势',
		seventhLineTitle: '连接趋势',
	},
	portalMac: {
		pick_placename: '请选择场所',
		pick_auth_name: '请选择认证域',
		pick_type_name: '请选择认证类型',
		pick_status: '请选择在线状态',
		input_phone: '请输入手机号',
		input_mac: '请输入终端mac',
		input_ip: '请输入Ip地址',
		selectTitle: "查询条件",
		type: '认证方式',
		tableName: "认证列表",
		visitorTableName: "访客列表",
		identity: "身份信息",
		type_name: "认证类型",
		auth_name: "认证域",
		upflow: "上行流量",
		downflow: "下行流量",
		usetime: "上网时长",
		status: "在线状态",
		created_at: "认证时间",
		updated_at: "更新时间",
		pickjt: '请选择集团',
		jt: '集团',
		id: 'ID',
		ip: 'IP地址',
		mac: '终端mac',
		in_flow: '上行流量',
		out_flow: '下行流量',
		flowcnt: '连接数',
		inrate: '入流速率',
		outrate: '出流速率',
		name: '用户名',
		day: '时间',
		device: '设备型号',
		device_id: '设备ID',
		device_name: '设备名称',
		placeName: '场所名称',
		authDate: '认证时间',
		upLimit: '上行限速',
		downLimit: '下行限速',
		ableDate: '有效期',
		aliveLastTime: '上次活跃时间',
		action: '操作',
		editItem: '编辑',
		chartTitle: '认证人数趋势图',
		personas: '用户画像',
		pickWg: '选择网关',
		phone: '手机号',
		authzone_id: '认证域ID',
		sdkVersion: 'SDK版本',
		ua: 'UA',
		browser: '浏览程序',
		platform: '平台',
		online: "在线",
		offline: "离线",
		input_username: '请输入工号/账号',
		username: '工号/账号'
	},
	appfilter: {
		showApplist:"应用统计",
		showinfo:"应用",
		tableName: '用户列表',
		hostname: '主机名称',
		mac: 'mac',
		ip: 'ip',
		applist: '常用应用',
		updated_at: '更新时间',

		app_class_visit_time:"app分类时间统计",
		dev_visit_time:"app时间统计",
		dev_visit_list:"app访问历史",
		appname:"app名称",
		total_time:"访问时间",
		first_time:"开始时间",
		latest_action:"过滤状态",
		filterate:"过滤",
		unfilterate:"未过滤",
	},
	bandwidth: {
		tableName: "带宽列表",
		wg: '网关',
		date: '日期',
		action: '操作',
		editItem: '编辑',
		chartTitle: '趋势图',
		chartTitle1: '上行趋势',
		chartTitle2: '下行趋势',
		chartTitle3: '小时趋势',
		pickWg: '选择网关',
		pickTimeStart: '选择一个开始时间',
		pickTimeEnd: '选择一个结束时间',
	},
	AppIdent: {
		tableName: "应用列表",
		name: '协议名称',
		connection: '连接数',
		up: '上行',
		down: '下行',
		d_up: '代理上行',
		d_down: '代理上行',
		count_flow: '累计流量',
		ten_flow: '最近10分钟流量',
		device_name: '设备名称',
		day: '日期',
		hour: '小时'
	},
	personas: {
		personas: '用户画像',
		phone: '手机号',
		mac: 'MAC地址',
		ua: '用户代理',
		authzone_name: '认证域',
		ip: 'IP地址',
		created_at: '最新认证时间',
		chartTitle: '应用展览',
	},
	server: {
		titleServer: '服务端管理',
		title: '认证库信息',
		titleBase: '认证库',
		titleDeleteAttribute: '删除属性',
		titleAddAttribute: '添加属性',
		wechatAdmin: {
			title: "二维码授权员",
			add: "添加授权员",
			openid: "openid",
			remark: "备注",
			create_at: "创建时间"
		},
		visitor: {
			title: "访客列表",
			add: "添加访客",
			create_at: "创建时间",
			name: "访客姓名",
			telnum: "访客手机号",
			mail: "访客邮箱",
			company: "访客部门",
			interviewee_name: "访者姓名"
		},
		bevisitor: {
			title: "被访客列表",
			add: "添加被访客",
			create_at: "创建时间",
			name: "访客姓名",
			telnum: "被访客手机号",
			mail: "被访客邮箱",
			company: "被访客部门",
			interviewee_name: "被访者姓名"
		},
		template: {
			download: '模板下载',
			import: '模板导入',
			filechoose: '文件选择'
		},
		table: {
			addServer: '添加radius',
			add: '新增认证信息',
			addBase: '新增认证库',
			name: '认证库名称',
			sessionTime: '上网时长(s)',
			idleTime: '空闲上网时长',
			filterId: '用户组或ACLID',
			aruba: '角色定义',
			password: '密码',
			username: '用户名',
			created_at: '创建时间',
			updated_at: '更新时间',
			radiusName: 'radius名称',
			radiusAddress: 'radius地址',
			radiusStatus: 'radius状态',
			radiusPort1: '计费端口',
			radiusPort2: '记账端口',
			tempclose: '暂停使用',
			remarks: '备注',
			disable_at: '暂停开始时间',
			expire_at: '到期时间',
			created_at: '创建时间',
			aruba_xml_key: 'aruba xml key',
			default_role: '默认角色'
		},
		group: {
			title: "认证组",
			name: "认证组名称",
			add: "新增认证组",
			editTitle: "编辑认证组",
            savemonth: "保存时长(单位月,为0时保存无上限)"
		},
		btn: {
			addRadius: '添加radius地址',
			add: '添加认证数据',
			edit: '编辑认证数据',
			addBase: '添加认证库',
			editBase: '编辑认证库',
			delete: '确认删除信息',
			restart: '重启',
			save: '保存端口',
			close: '关闭',
			open: '开启'
		},
		label: {
			baselist: '认证库属性列表',
			base: '请选择认证库',
			username: '用户名',
			password: '密码',
			charging: 'radius计费端口',
			accounting: 'radius记账端口',
			name: '认证库名称',
			sessionTime: '上网时长(s)',
			idleTime: '空闲上网时长',
			filterId: '用户组或ACLID',
			aruba: '角色定义',
			port: '请输入端口号',
			attr: 'radius属性',
			attrData: 'radius属性值',
			groupName: '认证组名称'
		},
		dialogs: {
			delete: '确认是否删除信息',
			titleDeleteAttribute: '确认是否删除该属性',
			save: '确认保存端口',
			restart: '确认重启Radius',
			open: '确认开启Radius',
			close: '确认关闭Radius',
		},
		rule: {
			name: '认证库名称不能为空',
			username: '用户名不能为空',
			password: '密码不能为空',
			radiusName: 'radius名称不能为空',
			radiusAddress: 'radius地址不能为空',
			port: '端口号不能为空',
			shop: '场所不能为空',
			group: '集团不能为空',
			attr: 'Radius属性不能为空',
			attrValue: 'Radius属性值不能为空',
			groupname: '认证组名不能为空',
			remark: '授权员备注不能为空',
			remarkLimit: '最多输入8个字符',
			authType: '认证库类型不能为空',
			ldap: 'Ldap服务器不能为空'
		},
		placeholder: {
			name: '请输入认证库名称',
			radiusName: '请输入radius名称',
			radiusAddress: '请输入radius地址',
			radiusport: '请输入端口号',
			attr: '请输入属性值',
			username: '请输入用户名',
			remarks: '请输入备注',
			visitor_name: '请输入访客名称',
			visitor_telnum: '请输入被访客手机号',
			visitor_mail: '请输入被访客邮箱',
			visitor_company: '请输入被访客公司名称',
			visitor_interviewee_name: '请输入被访客名称',
		}
	},
	client: {
		title: '客户端信息管理',
		table: {
			add: '新增客户端',
			nasid: 'NASID',
			nasip: 'NASIP',
			reqip: '请求IP',
			psk: 'PSK',
			base: '认证库',
			case: '场所',
			authzone: '认证域'
		},
		dialogs: {
			delete: '确认是否删除信息',
		},
		btn: {
			add: '新增客户端',
			edit: '编辑客户端',
			delete: '确认删除',
		},
		rule: {
			nasid: 'NASID不能为空',
			psk: 'PSK不能为空',
			base: '认证库不能为空',
			case: '场所不能为空',
			group: '集团不能为空',
			nasip: '请输入正确的ip地址'
		},
		label: {
			nasid: 'NASID',
			psk: 'PSK',
			base: '认证库',
			case: '场所',
			group: '集团',
		},
		placeholder: {
			nasid: '请输入nasid',
			psk: '请输入psk',
			nasip: '请输入nasip(留空则自动获取IP)',
			base: '请选择认证库',
			group: '请选择集团',
			case: '请选择场所',
			authzone: '请选择认证域',
			type: "认证库类型"
		},
		type: {
			ldap: "Ldap认证库",
			local: "本地认证库"
		}
		},
		gatewayManagement: {
		pickPlaceName: '选择场所',
		pickWg: '选择网关',
		tableName: "网关管理",
		id: 'ID',
		sdk: 'SDK',
		pickSdk: '添加SDK',
		add: '添加网关',
		shopName: '场所名称',
		groupName: '集团名称',
		placeName: '场所名称',
		name: '网关名称',
		config: '配置',
		connection: '',
		up: '上行',
		down: '下行',
		d_up: '代理上行',
		d_down: '代理上行',
		count_flow: '累计流量',
		ten_flow: '最近10分钟流量',
		addGatewaySuccess: '网关添加成功',
		editGatewaySuccess: '网关编辑成功',
		delGatewaySuccess: '网关删除成功',
		inputFull: '请填写必要的选项',
		smsgw_name: '网关名称'
	},
	apiManagement: {
		pickPlaceName: '选择场所',
		pickWg: '选择网关',
		tableName: "短信平台接口列表",
		name: '平台名字',
		sdkVersion: 'SDK版本',
		namespace: '命名空间',
		action: '操作',
		deleteSuccess: '删除成功',
		fileSuccess: '上传成功',
		fileNull: '，文件空，请选择文件',
	},
	sendingRecord: {
		pickPlaceName: '选择场所',
		pickWg: '选择集团',
		pickPhone: '匹配手机号',
		like: '匹配内容',
		tableName: "短信发送列表",
		phone: '手机号',
		info: '内容',
		name: '平台名字',
		sdkVersion: 'SDK版本',
		namespace: '命名空间',
		time: '时间',
	},
	device: {
		title: '网安设备列表',
		selectTitle: '选择网安筛选条件',
		btn: {
			exports: '导出',
			inputs: '导入',
			edit: '修改设备信息',
			delete: '确认删除',
		},
		txt: {
			null: '0',
			type1: '单审计设备',
			type2: '单嗅探设备',
			type3: '审计嗅探设备',
			baseInfoStatus0: '未报备',
			baseInfoStatus1: '报备中',
			baseInfoStatus2: '已报备',
			big: '大于0',
			status1: '正常',
			status2: '异常'
		},
		placeholder: {
			area: '请选择区域',
			company: '请选择集团',
			place: '请选择场所',
			name: '请输入名称',
			type: '请先择类型',
			status1: '请选择报备状态',
			status2: '请选择真实身份状态',
			status3: '请选择虚拟身份状态',
			status4: '请选择嗅探状态',
			status5: '请选择上行平台状态',
			mac: '请输入mac',
		},
		label: {
			pro: '省',
			city: '市',
			area: '区',
			company: '集团',
			place: '场所',
			name: '名称',
			mac: 'Mac',
			type: '类型',
			status1: '报备状态',
			status2: '真实身份状态',
			status3: '虚拟身份状态',
			status4: '嗅探状态',
			status5: '上行平台状态',
		},
		rule: {
			name: '名称不能为空',
			mac: 'Mac不能为空',
			type: '类型不能为空',
			group: '集团不能为空',
			shop: '场所不能为空'
		},
		table: {
			add: '添加设备',
			id: 'ID',
			pro: '省',
			city: '市',
			area: '区',
			company: '集团',
			place: '场所',
			name: '名称',
			type: '类型',
			status1: '报备状态',
			status2: '真实身份状态',
			status3: '虚拟身份状态',
			status4: '嗅探状态',
			status5: '上行平台状态',
			role1: '真实身份',
			upTime: '上线时间',
			mac: 'mac',
			apmac: 'apmac',
			role2: '虚拟身份',
			role2Type: '虚拟身份类型',
		}
	},
	gateway: {
		implementOk: '已执行',
		implementCancel: '未执行',
		statusUp: '在线',
		statusDown: '离线',
		equipment: '网关设备',
		equipment2: '网安设备',
		title_waiting: "待绑定设备列表",
		title: '设备列表',
		title_wait: '待绑定设备列表',
		title_instructions: '历史指令详情列表',
		selecTitle: '选择设备列表条件',
		table: {
			pro: '省',
			city: '市',
			area: '区',
			company: '集团',
			place: '场所',
			name: '名称',
			status: '状态',
			license: '授权',
			deviceId: '序列号',
			os: '系统版本',
			uptime: '心跳时间',
			version: '网关版本',
			instructions: '指令',
			mac: 'Mac',
		},
		tableExpand: {
			powerOk: '已授权',
			powerCancel: '未授权',
			progress: '进程',
			msg: '信息',
			abnormal: '异常信息',
			memory: '内存占比',
			cpu: '使用情况',
			disk: '磁盘挂载信息',
			diskname: '磁盘名称',
			diskposition: '位置',
			disktype: '类型',
			availableSpace: '可用空间',
			useSpace: '使用空间',
			bandwidth: '带宽',
			bandname: '接口名称',
			bandpocketS: '发送包数',
			bandnumberS: '发送字节数',
			bandpocketR: '接收包数',
			bandnumberR: '接收字节数',
			listenPort: '监听端口信息',
			listenProgress: '进程信息',
			listenName: '进程文件名称',
			listenCmds: '进程参数',
			listenType: '监听端口类型',
			listenPortN: '监听端口',
			listenPath: '进程路径',
			dhcpLeases: '已分配的客户端列表',
			dhcpName: '名称',
			dhcpTime: '时间',
			dhcpInterface: '接口状态',
			command: '进程',
			pid: 'Pid',
		},
		label: {
			areaPro: '省',
			areaCity: '市',
			areaArea: '区',
			company: '集团',
			issue:'指令',
			place: '场所',
			name: '名称',
			type: '类型',
			status: '状态',
			mac: 'Mac',
		},
		rule: {
			name: '名称不能为空',
			gateway: '网关数不能为空',
			ins: '指令不能为空',
			device: '设备不能为空',
		},
		btn: {
			add_ins: '添加指令',
			add: '添加网关设备',
			issue:	'下发指令',
			exports: '导出',
			inputs: '导入',
			edit: '修改设备信息',
			editTheme: '绑定设备信息',
			delete: '确认删除',
			join: '加入网安',
			login: '远程登录',
			look: '查看详情',
			remote: '添加远程指令',
			used: '执行指令',
			go: '网关',
            showmwan: '查看mwan',
            mwantop: '窝蜂流量排行'
		},
		placeholder: {
			areaPro: '请选择省',
			areaCity: '请选择市',
			areaArea: '请选择区',
			company: '请选择集团',
			place: '请选择场所',
			name: '请输入名称',
			type: '请先择类型',
			status: '请选择状态',
			inputname: '请输入名称',
			mac: '请输入Mac',
			ip: '请输入Ip'
		},
		instructions: {
			ins: '指令',
			name: '指令名称',
			placeholder: '请输入指令',
			equipment: '网关设备',
			status: '状态',
			time: '时间',
			step1: '第一步',
			step2: '第二步',
			step3: '第三步',
			pre: '上一步',
			next: '下一步',
		}
	},
	bigScreen: {
		title: '云平台大屏',
		left1: '网关在线率',
		left2: '每日认证图',
		left3: '用户在线率',
		left4: '应用连接数TOP',
		right1: '网安在线率',
		right2: '上行流量趋势',
		right3: '下行流量趋势',
		chartTitle2_1: '连接趋势',
		firstTitle1: '网关在线/总数',
		firstTitle2: '连接数 TCP/UDP/IMCP',
		firstTitle3: '在线/今日累计用户',
		firstTitle4: '网安在线/总数',
	},
	bigScreen3: {
		topLeftTitle1: '实时网关在线率',
		topLeftTitle2: '实时网安在线率',
		online: '在线',
		outline: '离线',
		topMiddleTitle: '连接趋势图',
	},
	userflow: {
		device_name: '设备名称',
		mac: 'mac地址',
		in_flow: '下行总流量',
		out_flow: '上行总流量',
		flowcnt: '连接数',
		inrate: '平均下行速率',
		outrate: '平均上行速率',
		maxinrate: '最大下行速率',
		maxoutrate: '最大上行速率',
		onlinesec: '在线时间',
	},
	license: {
		title: "License管理",
		upload: "上传lcs文件",
		download: "下载lcr文件",
		cancel: "取消授权",
		serialNum: "序列号",
		expireDate: "到期时间",
		status: {
			title: "状态",
			true: "已授权",
			false: "未授权"
		},
		fail: "请上传lcs文件",
		error: "上传失败",
		dialogText: '是否确认取消授权？'
	},
	email: {
		table: {
			title: "邮箱列表",
			name: "名称",
			host: "SMTP服务器地址",
			port: "端口",
			username: "邮箱地址",
			password: "密码",
			group: "集团",
			shop: "场所"
		},
		placeholder: {
			host: "SMTP服务器地址（示例：sina.smtp.com）",
			port: "端口（SMTP服务的默认端口为25）",
		},
		btn: {
			add: "添加邮箱",
			delete: "删除邮箱",
			edit: "修改邮箱信息",
		},
		rules: {
			name: "名称不能为空",
			host: "SMTP服务器地址不能为空",
			port: "端口号格式错误",
			username: "邮箱格式错误",
			password: "密码不能为空",
			group: "集团不能为空",
			shop: "场所不能为空"
		}
	},
	alert: {
		btn: {
			add: '添加告警规则',
			bindGateway: '绑定网关',
			save: '保存',
			del: '删除'
		},
		alertRecord: "告警记录",
		table: {
			name: '规则名称',
			active: '启用',
			inactive: '停用',
			from_name: '发件人姓名',
			editing: '编辑中...',
			isActive: '已启用',
			notActive: '已停用',
			time: '记录时间'
		},
		form: {
			fromName: '发件人姓名',
			title: '邮件标题',
			content: '邮件内容',
			email: '发送者邮箱',
			addresses: '收件人',
			ccs: '抄送人',
		},
		tip: {
			shopEmpty: '如果场所为空则该规则对所有场所用户不可见',
			addAddress: '按回车键进行添加'
		},
		rules: {
			name: '规则名称不能为空',
			issue: "指令不能为空",
			title: '邮件标题不能为空',
			content: '邮件内容不能为空',
			email: '邮件服务器不能为空',
			group: '集团不能为空',
			address: '收件人不能为空',
			fromName: '发件人姓名不能为空',
			eamilFormatErr: '邮箱格式错误'
		},
		deleteTitle: '删除规则'
	},
	ldap: {
		table: {
			title: "Ldap列表",
			editTitle: "Ldap配置编辑",
			addTitle: "添加Ldap配置",
			name: "名称",
			host: "Ldap服务器地址",
			port: "端口",
			admin_dn: "管理员账号",
			admin_pwd: "管理员密码"
		},
		btn: {
			add: "添加Ldap配置"
		},
		rules: {
			admin_dn: "管理员账号不能为空",
			admin_pwd: "管理员密码不能为空",
			base_dn: "base_dn不能为空",
			filter: "filter不能为空"
		}
	},
	radacctStatistic:{
		selectTitle: "查询条件",
		jt: '集团',
		pickjt: '请选择集团',
		placeName: '场所名称',
		pick_placename: '请选择场所',
		pickDateRange: '选择查询时间段',

		tableTitle: '场所计费',
		exportBtn:'导出报表',
		
		editBtn: '编辑',
		deleteBtn: '删除',
		searchBtn: '查询',
		close: '取消',
		confirm: '确认',
		
	},
	radacctRule:{
		title:'计费策略组',
		searchBtn: '查询',
		close: '取消',
		confirm: '确认',
		save: '保存',
		update: '更新',
		delTooltip: '确认删除数据？',
		pickMonthRange: '选择生效月份范围',

		warning: '请求失败',
    	success: '操作成功',

		btn:{
			addBtn:'添加策略组',
			editBtn: '编辑',
			deleteBtn: '删除',
		},

		table:{
			id:'序号',
			name:'名称',
			action:'操作',
			rule: '规则',

		},
	},
}