<template>
  	<v-data-table
		:headers="headers"
		:search="search"
		:items="desserts"
		class="elevation-1"
		>
		<template v-slot:top>
			<v-toolbar flat color="white">
				<v-toolbar-title>{{title}}</v-toolbar-title>
				<v-divider
				class="mx-4"
				inset
				vertical
				></v-divider>
				<v-btn color="primary" dark class="mb-2" @click="handleExport">{{$vuetify.lang.t('$vuetify.common.export')}}</v-btn>
				<v-spacer></v-spacer>
				<v-text-field
					v-model="search"
					label="Search"
					single-line
					hide-details
				></v-text-field>
				<v-dialog v-model="dialog" max-width="500px">
				<v-card>
					<v-card-title>
					<span class="headline">{{ formTitle }}</span>
					</v-card-title>

					<v-card-text>
						<v-container>
							<v-row>
							<v-col cols="12" sm="6" md="12">
								<v-text-field v-model="editedItem.id" :label="$vuetify.lang.t('$vuetify.portalMac.id')" ></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="12">
								<v-text-field v-model="editedItem.name" :label="$vuetify.lang.t('$vuetify.portalMac.name')" ></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="12">
								<v-text-field v-model="editedItem.device" :label="$vuetify.lang.t('$vuetify.portalMac.device')" ></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="12">
								<v-text-field v-model="editedItem.mac" :label="$vuetify.lang.t('$vuetify.portalMac.mac')"></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="12">
								<v-text-field v-model="editedItem.placeName"  :label="$vuetify.lang.t('$vuetify.portalMac.placeName')"></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="12">
								<v-text-field v-model="editedItem.authDate" :label="$vuetify.lang.t('$vuetify.portalMac.authDate')"></v-text-field>
							</v-col>
							</v-row>
						</v-container>
					</v-card-text>

					<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
					<v-btn color="blue darken-1" text @click="save">Save</v-btn>
					</v-card-actions>
				</v-card>
				</v-dialog>
			</v-toolbar>
		</template>
		<template v-slot:item.action="{ item }">
			<v-icon
				small
				class="mr-2"
				@click="editItem(item)"
			>
				edit
			</v-icon>
			<v-icon
				small
				@click="deleteItem(item)"
			>
				delete
			</v-icon>
		</template>
		<template v-slot:no-data>
		</template> 
		<template v-slot:item.acrossThree="{ item }">
			<v-chip :color="getColor(item.acrossThree)" dark>{{ item.acrossThree }}</v-chip>
		</template>
		<template v-slot:item.macAuth="{ item }">
			<v-chip :color="getColor1(item.macAuth)" dark>{{ item.macAuth }}</v-chip>
		</template>
	</v-data-table>
</template>

<script>
  export default {
    data () {
      return {
      }
    },
    props: {
      title:{
        type: String,
        default: '',
      },
      singleExpand: {
        type: Boolean,
        default: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      search: {
        type: String,
        default: '',
      },
      headers: {
        type: [Object, Array],
        default: ()=>{},
      },
      desserts: {
        type: [Object, Array],
        default: ()=>{},
      },
	},
	method: {
		handleExport(){
            // CsvExport(this.headers,this.desserts,this.title);
        },
        initialize() {

        },
        editItem (item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem (item) {
            const index = this.desserts.indexOf(item)
            confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)
        },

        close () {
            this.dialog = false
            setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            }, 300)
        },

        save () {
            if (this.editedIndex > -1) {
            Object.assign(this.desserts[this.editedIndex], this.editedItem)
            } else {
            this.desserts.push(this.editedItem)
            }
            this.close()
        },

        getColor (item) {
            if (item == '是') return 'green'
            else return 'grey'
        },
        getColor1 (item) {
            if (item == '已开启') return 'green'
            else return 'grey'
        },
        busFunction(){
            
        },
	}
  }
</script>

<style scoped>

</style>