import Vue from 'vue'
import Vuetify from 'vuetify'
import light from './theme'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import VueI18n from 'vue-i18n'

Vue.use(Vuetify, {
    iconfont: 'mdi',
})
Vue.use(VueI18n)

import {enLanguage} from './en.js'
import {zhLanguage} from './zh.js'

import {Zen} from './z-en.js'
import {Zzh} from './z-zh.js'

const messages = {
  en: {
    $vuetify: Object.assign(enLanguage, Zen),
  },
  zh: {
    $vuetify: Object.assign(zhLanguage, Zzh),
  },
}
let lang = localStorage.getItem('language')
let locale = lang == 'en'?'en':'zh'
const i18n = new VueI18n({
  locale: locale, // set locale
  messages, // set locale messages
  silentTranslationWarn: true
})

export default new Vuetify({
	lang: {
    		t: (key, ...params) => i18n.t(key, params),
  	},
})
